export const menuItems = [
  {
    name: "#services",
    label: "What we offer",
    isExternal: false,
  },
  {
    name: "#portfolio",
    label: "Portfolio",
    isExternal: false,
  },
  {
    name: "#about",
    label: "About",
    isExternal: false,
  },
  {
    name: "#quotation",
    label: "Contact",
    isExternal: false,
  }
];
