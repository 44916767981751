import React, { useContext } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { A } from "../Core";

const Footer = () => {
    const gContext = useContext(GlobalContext);

    const contactClassName =
        gContext.footer.theme === "dark" ? "gr-text-color" : "text-primary";

    const linkClassName =
        gContext.footer.theme === "dark"
            ? "gr-text-color gr-hover-text-green"
            : "gr-text-color gr-hover-text-blue";

    const iconClassName =
        gContext.footer.theme === "dark"
            ? "gr-text-color"
            : "gr-text-color gr-hover-text-blue";

    const copyClassName =
        gContext.footer.theme === "dark"
            ? "gr-text-color-opacity"
            : "gr-text-color";

    return (
        <>
            <div
                className={`footer-section ${gContext.footer.theme === "dark"
                    ? "bg-blackish-blue dark-mode-texts"
                    : ""
                    }`}
            >
                <Container>
                    <div className="footer-top pt-14 pt-lg-25 pb-lg-19" style={{ justifyContent: "space-around" }}>
                        <Row style={{ justifyContent: "center" }}>
                            <Col xs="6" lg="4">
                                <div className="single-footer mb-13 mb-lg-9">
                                    <h3>The Company</h3>
                                    <p className="footer-title gr-text-11 mb-7 text-color-opacity">GBA Company is a clothing manufacturer ideal for retailers, wholesalers, and businesses that need uniforms and clothing for their employees. Based in Bulgaria and having more than 30 years of experience, we provide top-quality apparel for EU brands equipped with highly trained employees and state-of-the-art technology.</p>
                                </div>
                            </Col>
                            <Col xs="6" lg="2">
                                <div className="single-footer mb-13 mb-lg-9">
                                    <p className="footer-title gr-text-11 mb-7 text-color-opacity">Sections</p>
                                    <ul className="footer-list list-unstyled">
                                        <li className="py-2">
                                            <a href="#services" className={`gr-text-9 ${linkClassName}`}>
                                                What we offer
                                            </a>
                                        </li>
                                        <li className="py-2">
                                            <a href="#about" className={`gr-text-9 ${linkClassName}`}>
                                                About us
                                            </a>
                                        </li>
                                        <li className="py-2">
                                            <a href="#portfolio" className={`gr-text-9 ${linkClassName}`}>
                                                Portfolio
                                            </a>
                                        </li>
                                        <li className="py-2">
                                            <a href="#quotation" className={`gr-text-9 ${linkClassName}`}>
                                                Contact us
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm="6" lg="3">
                                <div className="single-footer mb-13 mb-lg-9">
                                    <p className="footer-title gr-text-11 mb-7 text-color-opacity">Contact us</p>
                                    <ul className="footer-list list-unstyled">
                                        <li className="py-2">
                                            <a
                                                className={`gr-text-9 fw-bold hover-underline active ${contactClassName}`}
                                                href="mailto:info@gbacompany.eu"
                                            >
                                                info@gbacompany.eu
                                            </a>
                                        </li>
                                        {/* <li className="py-2">
                                            <a
                                                className={`gr-text-9 fw-bold hover-underline active ${contactClassName}`}
                                                href="tel:+35974342061"
                                            >
                                                +359 74 342 061
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="copyright-area border-top py-9">
                        <Row className="align-items-center">
                            <Col lg="6">
                                <p
                                    className={`copyright-text gr-text-11 mb-6 mb-lg-0 text-center text-lg-start ${copyClassName}`}
                                >
                                    © 2022 Copyright GBA Company,  <a href="https://w3vitals.com" target="_blank"><span style={{color:"#161c2d"}}>Website development by</span> W3Vitals.</a>

                                </p>
                            </Col>
                            <Col lg="6" className="text-center text-lg-end">
                                <ul className="social-icons list-unstyled mb-0">
                                    {/* <li className="ms-7">
                                        <Link to="/" className={iconClassName}>
                                            <i className="icon icon-logo-twitter"></i>
                                        </Link>
                                    </li> */}
                                    <li className="ms-7">
                                        <a href="https://www.facebook.com/GBAcompany" target="_blank" className={iconClassName}>
                                            <i className="icon icon-logo-facebook"></i>
                                        </a>
                                    </li>
                                    {/* <li className="ms-7">
                                        <Link to="/" className={iconClassName}>
                                            <i className="icon icon-instant-camera-2"></i>
                                        </Link>
                                    </li> */}
                                    {/* <li className="ms-7">
                                        <a href="#" className={iconClassName}>
                                            <i className="icon icon-logo-linkedin"></i>
                                        </a>
                                    </li> */}
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Footer;
